@charset "UTF-8";
/*
	CSS Document
	Author: Nagaoka Design Office
	version: 1.2
	Last Update: 2022.12.13
	web fonts:
	font-weight: 400, 700

	main color:
	100%: #5C7686
	75%: #8598A4
	50%: #ADBAC2
	25%: #D6DDE1
*/

$mq-breakpoints: (
  xl: 1440px,
  lg: 1280px,
  md: 1024px,
  tb: 768px,
  sm: 576px,
  xs: 414px,
) !default;

// Media Query
@mixin mq($breakpoint: md, $rule: min, $subtraction: false) {
  $breakpoint: map_get($mq-breakpoints, $breakpoint);

  @if $rule==max and $subtraction {
    $breakpoint: $breakpoint - 0.0625;
  }

  @media screen and (#{$rule}-width: $breakpoint) {
    @content;
  }
}
/* =======================
	Common
========================= */
body {
  font-family: serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.75em;
  background-color: whitesmoke;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
#Wrapper {
  width: 100%;
  margin: 0 auto;
  background-color: white;
  @include mq(sm) {
    position: relative;
    width: 576px;
  }
}
/* =======================
	header
========================= */
header {
  position: fixed;
  top: 0;
  left: auto;
  right: auto;
  width: 100%;
  height: 60px;
  margin: 0 auto;
  background-color: #5c7686;
  overflow: hidden;
  border-bottom: 1px solid white;
  z-index: 2000;
  @include mq(sm) {
    width: 576px;
  }
  .logo {
    display: block;
    width: 60px;
    height: 0;
    padding-top: 60px;
    margin: 0 auto;
    overflow: hidden;
    background: url("../images/img_logo.svg") center center no-repeat;
    background-size: contain;
    a {
      display: block;
      height: 60px;
    }
  }
}
/* =======================
	nav
========================= */
/* Hamburger */
#HamburgerMenu {
  position: fixed;
  top: 0;
  right: 0;
  box-sizing: content-box;
  padding: 15px;
  width: 30px;
  height: 30px;
  background: none;
  border-radius: 0;
  z-index: 2001;
  @include mq(sm) {
    right: 50%;
    transform: translateX(288px);
  }
}
#MenuTrigger {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  transition: all 0.5s;
  box-sizing: border-box;
  span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    display: inline-block;
    border-radius: 0;
    transition: all 0.5s;
    box-sizing: border-box;
    //
    &:nth-of-type(1) {
      top: 6px;
      background-color: white;
    }
    //
    &:nth-of-type(2) {
      top: 15px;
      background-color: white;
    }
    //
    &:nth-of-type(3) {
      bottom: 5px;
      background-color: white;
    }
  }
  //
  &.active {
    span {
      //
      &:nth-of-type(1) {
        transform: translateY(9px) rotate(-225deg);
      }
      //
      &:nth-of-type(2) {
        opacity: 0;
      }
      //
      &:nth-of-type(3) {
        transform: translateY(-9px) rotate(225deg);
      }
    }
  }
}
/* Navigation Menu */
#Navigation {
  display: none;
  position: fixed;
  top: 60px;
  left: auto;
  right: auto;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0 30px 80px;
  background-color: #5c7686;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
  z-index: 1999;
  overflow: scroll;
  @include mq(sm) {
    width: 576px;
  }
  //
  .language {
    display: flex;
    justify-content: center;
    text-align: center;
    a {
      display: inline-block;
      margin: 28px 5px;
      padding: 9px 16px 8px;
      font-size: 16px;
      line-height: 16px;
      color: #5c7686;
      background-color: #adbac2;
      border-radius: 4px;
      &.current {
        color: #5c7686;
        background-color: white;
        pointer-events: none;
      }
    }
  }
  //
  .menubox {
    display: block;
    border-top: 1px solid white;
    .menu {
      color: #ffffff;
      display: block;
      border-bottom: 1px solid white;
      &__main {
        position: relative;
        display: block;
        height: 50px;
        padding: 16px 0 16px 1em;
        font-size: 18px;
        line-height: 18px;
      }
      .keymenu {
        &:hover {
          cursor: pointer;
        }
        &:active {
          transform: translateY(2px);
        }
        &::after {
          position: absolute;
          right: 0;
          top: 17px;
          content: "";
          width: 24px;
          height: 16px;
          background: url("../images/key-down.svg") right center no-repeat;
          background-size: 24px auto;
          transition: all 0.5s;
        }
      }
      .up {
        &::after {
          transform: rotateZ(180deg);
        }
      }
      &__sub {
        display: none;
        padding: 0 0 0.5em 2em;
        li {
          font-size: 16px;
          line-height: 1.25em;
          padding: 0.5em 0;
        }
      }
      a {
        display: block;
        color: white;
        text-decoration: none;
        &:visited {
          color: white;
        }
      }
    }
  }
}
/* =======================
	Main
========================= */
main {
  display: block;
  width: 100%;
  min-height: 96vh;
  margin: 0 auto;
  padding: 61px 0 0;
  background-color: white;
  color: #707070;
}
/* =======================
	Top Menu
========================= */
.topnavi {
  position: fixed;
  top: 60px;
  left: auto;
  right: auto;
  display: block;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  height: 60px;
  margin: 0 auto;
  padding: 12px 0;
  background-color: white;
  z-index: 1900;
  @include mq(sm) {
    width: 576px;
  }
  white-space: nowrap;
  div {
    min-width: 18%;
    display: inline-block;
    margin: 0px 2px 0px 2px;
    padding: 0px 12px 6px;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    font-feature-settings: "palt";
    a {
      color: #adbac2;
      display: inline-block;
    }
    &.current {
      border-bottom: 2px solid #5c7686;
      a {
        color: #5c7686;
      }
    }
  }
}
.toc {
  overflow: hidden;
  margin: 140px 0 0 20px;
}
.toc a {
  display: block;
  font-size: 18px;
  color: #5c7686;
}
/* =======================
	Content / Common
========================= */
.content {
  overflow: hidden;
  &__innerbox {
    margin: 70px auto 0;
    text-align: center;
    &--select {
      display: inline-block;
      margin: 20px auto;
    }
  }
  &__drink {
    margin: 120px auto 0;
  }
  &__brand {
    margin: 120px auto 0;
  }
  &__brand#MonthlyMix {
    margin: 0 auto;
  }
}

#MonthlyMix {
  margin-top: 120px !important;
}

.content h2 {
  color: #5c7686;
}

.post {
  overflow: hidden;
  width: 90%;
  margin: 0 auto;
  img {
    margin-top: 10px;
    display: block;
    width: 100%;
    height: auto;
  }
}
.non-nicotine-menu {
  overflow: hidden;
  text-align: center;
}
.non-nicotine-menu .menu-content {
  display: flex; /* Flexboxを使用して子要素を横に並べる */
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.non-nicotine-menu .menu-content picture {
  width: 35%;
  margin-right: 10px;
}
.non-nicotine-menu .menu-content img {
  width: 100%;
  margin: 0 auto;
}
.non-nicotine-menu .menu-title {
  display: block;
  width: 55%;
}
.non-nicotine-menu .menu-title p {
  font-size: 18px;
}
/* =======================
	System
========================= */
.system {
  width: 86%;
  margin: 24px auto 12px;
  &__headline {
    display: inline-block;
    margin: 1.2em auto 0.8em;
    font-size: 22px;
    font-weight: 700;
    line-height: 1em;
    text-align: center;
    color: #505050;
  }
  &__order {
    position: relative;
    &--title {
      text-align: left;
      height: 30px;
      line-height: 30px;
      vertical-align: middle;
    }
    &--price {
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: 30px;
      color: #5c7686;
      font-family: Georgia, "Times New Roman", Times, "serif";
      font-size: 15px;
      font-weight: 400;
      line-height: 30px;
      text-align: right;
      vertical-align: middle;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: calc(100% - 4em);
        height: 30px;
        vertical-align: middle;
        background: url("../images/bar.svg") left center repeat-x;
        background-size: auto 15px;
      }
    }
    &--notice {
      padding: 0.5em 0;
      font-size: 13px;
      font-weight: 400;
      line-height: 1.5em;
      text-align: justify;
    }
  }
  &__option {
    position: relative;
    &--title {
      text-align: left;
      height: 30px;
      line-height: 30px;
      vertical-align: middle;
    }
    &--price {
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: 30px;
      color: #5c7686;
      font-family: Georgia, "Times New Roman", Times, "serif";
      font-size: 15px;
      font-weight: 400;
      line-height: 30px;
      text-align: right;
      vertical-align: middle;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: calc(100% - 4em);
        height: 30px;
        vertical-align: middle;
        background: url("../images/bar.svg") left center repeat-x;
        background-size: auto 15px;
      }
    }
    &--notice {
      padding: 0.5em 0;
      font-size: 13px;
      font-weight: 400;
      line-height: 1.5em;
      text-align: justify;
    }
  }
  &__wifi {
    font-size: 15px;
    font-weight: 400;
    &__info {
      max-width: 10em;
      margin: 0 auto;
      text-align: left;
    }
  }
  &__caution {
    p {
      padding: 0 0 0.5em 1em;
      font-size: 15px;
      font-weight: 400;
      text-align: justify;
      line-height: 1.4em;
      text-indent: -1em;
      font-feature-settings: "palt";
    }
  }
}
/* =======================
	Drink
========================= */
.drinkmenu {
  position: fixed;
  top: 120px;
  left: auto;
  right: auto;
  display: block;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  background: white;
  z-index: 1950;
  @include mq(sm) {
    width: 576px;
  }
  ul {
    display: block;
    width: auto;
    white-space: nowrap;
    li {
      display: inline-block;
      margin: 10px 2px 10px 2px;
      padding: 7px 12px 6px;
      font-size: 16px;
      line-height: 16px;
      font-feature-settings: "palt";
      white-space: nowrap;
      border: 1px solid #5c7686;
      background-color: white;
      overflow: hidden;
      border-radius: 16px;
      a {
        color: #5c7686;
      }
      &:first-of-type {
        margin: 10px 2px 10px 20px;
      }
      &:last-of-type {
        margin: 10px 20px 10px 2px;
      }
      &.current {
        background-color: #5c7686;
        a {
          color: white;
        }
      }
    }
  }
}
.headline {
  display: block;
  width: calc(100% - 30px);
  margin: 0 auto;
  padding: 1em 0 0.5em;
  font-size: 22px;
  font-weight: 400;
  line-height: 1em;
  text-align: center;
  color: #505050;
  border-top: 1px solid #adbac2;
}
.method {
  display: block;
  width: 86%;
  margin: 0 auto 30px;
  list-style: none;
  overflow: hidden;
  color: #5c7686;
  &__li {
    display: block;
    width: calc(100% - 30px);
    margin: 10px auto;
    &--title {
      position: relative;
      width: 100%;
      margin: 0 auto;
    }
    &--name {
      padding: 0 4em 0 0;
      font-size: 15px;
      font-weight: 400;
      text-align: left;
      font-feature-settings: "palt";
      line-height: 1.25em;
    }
    &--price {
      position: absolute;
      top: 0;
      right: 0;
      width: 4em;
      padding: 0 0 0 0;
      font-family: Georgia, "Times New Roman", Times, "serif";
      font-size: 15px;
      font-weight: 400;
      text-align: left;
      line-height: 1.25em;
    }
  }
}
.drink {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  overflow: hidden;
  color: #5c7686;
  &__li {
    display: block;
    width: calc(50% - 20px);
    margin: 5px 5px 10px;
    &--title {
      position: relative;
      width: 100%;
    }
    &--name {
      padding: 6px 4.2em 6px 4px;
      font-size: 15px;
      font-weight: 400;
      text-align: left;
      font-feature-settings: "palt";
      line-height: 1.25em;
    }
    &--price {
      position: absolute;
      top: 0;
      right: 0;
      padding: 6px 8px 6px 0;
      font-family: Georgia, "Times New Roman", Times, "serif";
      font-size: 15px;
      font-weight: 400;
      text-align: right;
      line-height: 1.25em;
    }
    //
    &.wide {
      width: calc(100% - 30px);
    }
    //
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}
.sweets {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  overflow: hidden;
  color: #5c7686;
  &__li {
    display: block;
    width: calc(50% - 20px);
    margin: 5px 5px 10px;
    &--title {
      position: relative;
      width: 100%;
    }
    &--name {
      padding: 6px 4.2em 6px 4px;
      font-size: 15px;
      font-weight: 400;
      text-align: left;
      font-feature-settings: "palt";
      line-height: 1.25em;
    }
    &--price {
      position: absolute;
      top: 0;
      right: 0;
      padding: 6px 8px 6px 0;
      font-family: Georgia, "Times New Roman", Times, "serif";
      font-size: 15px;
      font-weight: 400;
      text-align: right;
      line-height: 1.25em;
    }
    //
    &.wide {
      width: calc(100% - 30px);
    }
    //
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}
.thumb {
  position: relative;
  display: block;
  img {
    display: block;
  }
  &:hover {
    cursor: pointer;
  }
  &:active {
    transform: translateY(1px);
  }
  &::after {
    content: "";
    position: absolute;
    bottom: 5px;
    left: 5px;
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: #000 url("../images/zoom.svg") center center no-repeat;
    background-size: 10px 10px;
    opacity: 0.5;
  }
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  display: none; // Default
  width: 100%;
  height: 100%;
  z-index: 3000;
  overflow-y: scroll;
  &__innerbox {
    position: relative;
    width: 90%;
    max-width: 520px;
    margin: 30px auto 10px auto;
    padding: 16px;
    background-color: white;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    z-index: 2;
    &--title {
      position: relative;
      width: 100%;
      border-bottom: 1px solid #adbac2;
    }
    &--name {
      padding: 12px 3em 12px 2px;
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      font-feature-settings: "palt";
      line-height: 1.25em;
    }
    &--price {
      position: absolute;
      top: 0;
      right: 0;
      padding: 12px 4px 12px 0;
      font-family: Georgia, "Times New Roman", Times, "serif";
      font-size: 16px;
      font-weight: 400;
      text-align: right;
      line-height: 1.25em;
    }
    &--text {
      padding: 10px 0;
      font-size: 15px;
      font-weight: 400;
      line-height: 1.5em;
      text-align: justify;
    }
  }
  .close {
    position: absolute;
    right: -8px;
    top: -18px;
    width: 36px;
    height: 36px;
    background: #d6dde1 url("../images/close-b.svg") center center no-repeat;
    background-size: 20px 20px;
    color: white;
    text-align: center;
    border-radius: 18px;
    overflow: hidden;
    text-indent: -999px;
    &:hover {
      cursor: pointer;
    }
    &:active {
      transform: translateY(1px);
    }
  }
  &::before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(92, 118, 134, 0.75);
    z-index: -1;
  }
}
/* =======================
	Brand & Genre
========================= */
.brandmenu {
  position: fixed;
  top: 120px;
  left: auto;
  right: auto;
  display: block;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  background: white;
  z-index: 1950;
  @include mq(sm) {
    width: 576px;
  }
  ul {
    display: block;
    width: auto;
    white-space: nowrap;
    li {
      display: inline-block;
      margin: 10px 2px 10px 2px;
      padding: 7px 12px 6px;
      font-size: 16px;
      line-height: 16px;
      font-feature-settings: "palt";
      white-space: nowrap;
      border: 1px solid #5c7686;
      background-color: white;
      overflow: hidden;
      border-radius: 16px;
      a {
        color: #5c7686;
      }
      &:first-of-type {
        margin: 10px 2px 10px 20px;
      }
      &:last-of-type {
        margin: 10px 20px 10px 2px;
      }
      &.current {
        background-color: #5c7686;
        a {
          color: white;
        }
      }
    }
  }
}
.brand {
  display: block;
  width: 90%;
  margin: 0 auto;
  list-style: none;
  overflow: hidden;
  color: #222;
  &__li {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 10px auto 50px;
    &--leftbox {
      width: 70%;
      padding: 0 1em 0 0;
      border-right: 1px solid #d6dde1;
    }
    &--rightbox {
      width: 30%;
      ul {
        display: block;
        list-style: none;
        li {
          padding: 4px 0 4px 8px;
          color: #5c7686;
          font-size: 14px;
          line-height: 1.2em;
          text-align: right;
        }
      }
    }
    &--pict {
      width: auto;
      height: auto;
      max-height: 100px;
      img {
        width: auto;
        height: 100px;
      }
    }
    &--name {
      padding: 0.25em 0;
      font-size: 16px;
      line-height: 1.5em;
      text-align: left;
      font-feature-settings: "palt";
      margin-bottom: 0.3em;
    }
    &--desc {
      font-size: 14px;
      line-height: 1.5em;
      text-align: justify;
      font-feature-settings: "palt";
    }
  }
}
/* =======================
	Input
========================= */
/* selector */
select[name="brand"],
select[name="genre"] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 8px 28px 7px 10px;
  border: 1px solid #505050;
  color: #505050;
  border-radius: 0;
  font-size: 18px;
  line-height: 18px;
  background: url("../images/selector-bg.svg") right center no-repeat;
  background-size: 24px auto;
}

/* input select reset */
:where(:focus-visible) {
  outline: none;
}
/* =======================
	Toppage
========================= */
.toppage {
  margin: 30vh auto 0;
  text-align: center;
  button {
    display: inline-block;
    width: auto;
    margin: 0 3px;
    padding: 12px 1em;
    font-size: 16px;
    color: white;
    font-weight: 400;
    line-height: 16px;
    white-space: nowrap;
    text-align: center;
    background-color: #5c7686;
    border-radius: 4px;
    border: none;
    box-shadow: none;
    transition: background-color 0.2s linear;
    &:hover {
      cursor: pointer;
      background-color: #8598a4;
    }
    &:active {
      box-shadow: none;
      transform: translateY(2px);
    }
  }
}
/* =======================
	Footer
========================= */
footer {
  display: block;
  margin: 30px auto 0;
  background-color: #adbac2;
  .copyright {
    padding: 1em 0;
    font-size: 12px;
    text-align: center;
    color: white;
  }
}
